import React from 'react'
import Fade from 'react-reveal/Fade'
import {Link as ScrollLink} from 'react-scroll'

import Bartosjiri from '../../components/bartosjiri/Bartosjiri'

import style from './Footer.module.scss'

const Footer = () => (
  <footer className={style.footer}>
    <div className={style.container}>
      <Fade>
        <div className={style.sections}>
          <div className={style.item}>
            <ScrollLink
              to="top"
              smooth
              style={{cursor: 'pointer'}}
            >
              <span>Scroll to top</span>
            </ScrollLink>
          </div>
          <div className={style.item}>
            <Bartosjiri />
          </div>
        </div>
      </Fade>
    </div>
  </footer>
)

export default Footer
