import React from 'react'
import SVG from 'react-inlinesvg'

import BartosjiriIcon from '../../../static/assets/icons/bartosjiri/bartosjiri.svg'

import style from './Bartosjiri.module.scss'

const Bartosjiri = () => (
  <a
    className={style.bartosjiri}
    href="https://bartosjiri.com"
    target="_blank"
    rel="noopener noreferrer"
  >
    <SVG src={BartosjiriIcon} />
  </a>
)

export default Bartosjiri
