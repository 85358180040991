import getApiUrl from '../netlify/getApiUrl'

const getLivestreamStatus = async () => {
  const url = getApiUrl()

  try {
    const res = await fetch(`${url}/.netlify/functions/twitch-live`, {
      method: 'GET'
    })

    const data = await res.json()

    return data.live
  } catch (err) {
    return false
  }
}

export default getLivestreamStatus
