import React from 'react'
import PropTypes from 'prop-types'
import Headroom from 'react-headroom'
import Fade from 'react-reveal/Fade'

import HeaderContent from '../../components/header-content/HeaderContent'

import style from './Header.module.scss'

const Header = ({navActive, setNavActive}) => (
  <Headroom>
    <Fade top>
      <HeaderContent
        className={style.container}
        navActive={navActive}
        setNavActive={setNavActive}
      />
    </Fade>
  </Headroom>
)

Header.propTypes = {
  navActive: PropTypes.bool.isRequired,
  setNavActive: PropTypes.func.isRequired
}

export default Header
