// import React, {useEffect, useRef} from 'react'
import React, {useRef} from 'react'

// import useWindowSize from '../../util/window-size/useWindowSize'

import mainStyle from './Main.module.scss'

const Main = ({scrollEffect, children, ...props}) => {
  // const windowSize = useWindowSize()

  const app = useRef()
  const scrollContainer = useRef()

  // const scrollData = {
  //   ease: 0.1,
  //   current: 0,
  //   previous: 0,
  //   rounded: 0
  // }

  // const setBodyHeight = () => {
  //   document.body.style.height = `${scrollContainer.current.getBoundingClientRect().height}px`
  // }

  // useEffect(() => {
  //   setBodyHeight()
  // }, [windowSize.height, windowSize.width])

  // const skewScrolling = () => {
  //   scrollData.current = window.scrollY
  //   scrollData.previous += (scrollData.current - scrollData.previous) * scrollData.ease
  //   scrollData.rounded = Math.round(scrollData.previous * 100) / 100

  //   // const diff = scrollData.current - scrollData.rounded
  //   // const accel = diff / windowSize.width
  //   // const velo = +accel
  //   // const skew = velo * 10

  //   // scrollContainer.current.style.transform = `translate3d(0, -${scrollData.rounded}px, 0) skewY(${skew}deg)`
  //   scrollContainer.current.style.transform = `translate3d(0, -${scrollData.rounded}px, 0)`

  //   requestAnimationFrame(() => skewScrolling())
  // }

  // useEffect(() => {
  //   requestAnimationFrame(() => skewScrolling())
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  return (
    <main ref={app} className={mainStyle.main} id="top" {...props}>
      <div ref={scrollContainer}>
        {children}
      </div>
    </main>
  )
}

export default Main
