import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'gatsby'
import SVG from 'react-inlinesvg'
import Scroll from 'react-scroll'

import MingIcon from '../../../static/assets/icons/ming/ming.svg'

import style from './HeaderContent.module.scss'

const Header = ({
  logoLink = true, navActive, setNavActive, className, ...props
}) => {
  const scroll = Scroll.animateScroll

  return (
    <div
      className={`${style.header} ${className || ''}`}
      {...props}
    >
      <div className={style.container}>
        {logoLink
          ? (
            <Link
              to="/"
              onClick={() => scroll.scrollToTop()}
              className={style.logo}
            >
              <SVG src={MingIcon} />
            </Link>
          )
          : (
            <div className={style.logo}>
              <SVG src={MingIcon} />
            </div>
          )}
        <div
          className={`${style.menu} ${navActive && style.active}`}
          onClick={() => setNavActive()}
          role="button"
          aria-hidden="true"
        >
          <span />
          <span />
          <span />
        </div>
      </div>
    </div>
  )
}

Header.propTypes = {
  logoLink: PropTypes.bool,
  navActive: PropTypes.bool.isRequired,
  setNavActive: PropTypes.func.isRequired,
  className: PropTypes.string
}

export default Header
