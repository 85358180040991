import React, {useState} from 'react'
import PropTypes from 'prop-types'
import ScrollLock from 'react-scrolllock'

import Head from '../util/head/Head'
import Header from './header/Header'
import Nav from './nav/Nav'
import Main from './main/Main'
import Footer from './footer/Footer'

const Layout = ({
  title, scrollEffect = true, children, ...props
}) => {
  const [navActive, setNavActive] = useState(false)

  const handleMenuClick = () => {
    setNavActive(!navActive)
  }

  return (
    <>
      <Head title={title} />
      <Header
        navActive={navActive}
        setNavActive={handleMenuClick}
      />
      <Nav
        navActive={navActive}
        setNavActive={handleMenuClick}
      />
      <ScrollLock isActive={navActive} />
      <Main
        scrollEffect={scrollEffect}
        {...props}
      >
        {children}
      </Main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  title: PropTypes.string,
  scrollEffect: PropTypes.bool,
  children: PropTypes.node.isRequired
}

export default Layout
