import React from 'react'
import PropTypes from 'prop-types'

import style from './NavTag.module.scss'

const NavTag = ({
  color, background, className, children, ...props
}) => (
  <div
    className={`${style.tag} ${className || ''}`}
    {...props}
  >
    <span
      style={{color, background}}
    >
      {children}
    </span>
  </div>
)

NavTag.propTypes = {
  color: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired
}

export default NavTag
