import React from 'react'
import {ParallaxProvider} from 'react-scroll-parallax'

import Layout from '../../layout/Layout'

import Hero from './_sections/hero/Hero'
import Bio from './_sections/bio/Bio'
import Merch from './_sections/merch/Merch'
import Socials from './_sections/socials/Socials'

const IndexPage = () => (
  <ParallaxProvider>
    <Layout>
      <Hero />
      <Bio />
      <Merch />
      <Socials />
    </Layout>
  </ParallaxProvider>
)

export default IndexPage
