import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {Link} from 'gatsby'

import getLivestreamStatus from '../../services/twitch/getLivestreamStatus'

import HeaderContent from '../../components/header-content/HeaderContent'
import StyledLink from '../../components/styled-link/StyledLink'
import NavTag from '../../components/nav-tag/NavTag'
import Bartosjiri from '../../components/bartosjiri/Bartosjiri'

import ArrowLinkIcon from '../../../static/assets/icons/navigation/arrow-link.svg'

import socialsConfig from '../../../configuration/socials.yml'

import style from './Nav.module.scss'

const Nav = ({navActive, setNavActive, ...props}) => {
  const [live, setLive] = useState(false)

  const twitchSocial = socialsConfig && socialsConfig.length
    && socialsConfig.find(i => i.id === 'twitch')
  if (!twitchSocial) {
    // eslint-disable-next-line no-console
    console.error('[socials.yml] Missing Twitch config!')
  }

  useEffect(() => {
    (async () => {
      setLive(await getLivestreamStatus())
    })()
  }, [])

  return (
    <nav
      className={`${style.nav} ${navActive ? style.active : ''}`}
      {...props}
    >
      <div className={style.overlay}>
        <div className={style.container}>
          <HeaderContent
            logoLink={false}
            navActive={navActive}
            setNavActive={setNavActive}
          />
          <div className={style.list}>
            <div className={style.item}>
              <Link
                to="/"
                onClick={() => setNavActive(false)}
              >
                <StyledLink className={style.block}>
                  <span>Homepage</span>
                </StyledLink>
              </Link>
            </div>
            <div className={style.item}>
              <a
                href={twitchSocial && twitchSocial.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <StyledLink
                  className={style.block}
                  icon={ArrowLinkIcon}
                >
                  <span>Livestream</span>
                </StyledLink>
                {live && (
                  <NavTag
                    color="#ffffff"
                    background="red"
                  >
                    Currently live
                  </NavTag>
                )}
              </a>
            </div>
            <div className={style.item}>
              <Link to="/">
                <StyledLink className={style.block} disabled>
                  <span>Archive</span>
                </StyledLink>
                <NavTag
                  color="#000000"
                  background="#ffffff"
                >
                  Coming soon
                </NavTag>
              </Link>
            </div>
            <div className={style.item}>
              <Link to="/">
                <StyledLink className={style.block} disabled>
                  <span>Merch</span>
                </StyledLink>
                <NavTag
                  color="#000000"
                  background="#ffffff"
                >
                  Coming soon
                </NavTag>
              </Link>
            </div>
          </div>
          <div className={style.footer}>
            <div className={`${style.item} ${style.socials}`}>
              {socialsConfig && socialsConfig.length && socialsConfig.map(item => (
                <a
                  key={item.id}
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >{item.label}</a>
              ))}
            </div>
            <div className={style.item}>
              <Bartosjiri />
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

Nav.propTypes = {
  navActive: PropTypes.bool.isRequired,
  setNavActive: PropTypes.func.isRequired
}

export default Nav
