import React from 'react'
import Fade from 'react-reveal/Fade'

import Parallax from '../../../../components/parallax/Parallax'

import style from './Hero.module.scss'

const Hero = () => (
  <section
    name="Hero"
    className={style.hero}
  >
    <Fade left distance="25%">
      <div className={style.container}>
        <div className={style.title}>
          <h1>What we<br />playin'?</h1>
        </div>
      </div>
    </Fade>
    <Parallax className={style.background}>
      <div className={style.overlay} />
      <video autoPlay loop muted playsInline>
        {/* @TODO: Switch video source based on screen resolution */}
        {/* @TODO: Add fallback image */}
        <source src="./assets/videos/hero-background.mp4" type="video/mp4" />
      </video>
    </Parallax>
  </section>
)

export default Hero
